// If you change any of the fonts here make sure to update them in the corresponding less file for Angular JS
$default-fallback-font: Verdana, Tahoma, sans-serif;

$default-font-family-bold-name: "Simplifile Font Bold";
$default-font-family-light-name: "Simplifile Font Light";
$default-font-family-regular-name: "Simplifile Font Regular";

$default-font-family-name: "Simplifile Font";

$default-font-family-bold: $default-font-family-bold-name,
    $default-fallback-font;
$default-font-family-light: $default-font-family-light-name,
    $default-fallback-font;
$default-font-family-regular: $default-font-family-regular-name,
    $default-fallback-font;
$default-font-family: $default-font-family-name, $default-fallback-font;

@font-face {
    font-family: "simplifile-grid";
    src: url("/sf/ui/assets/font/icons/simplifile-grid.ttf?n3yow3")
            format("truetype"),
        url("/sf/ui/assets/font/icons/simplifile-grid.woff?n3yow3")
            format("woff"),
        url("/sf/ui/assets/font/icons/simplifile-grid.svg?n3yow3#simplifile-grid")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $default-font-family-regular-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Regular.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Regular.woff") format("woff");
}

@font-face {
    font-family: $default-font-family-bold-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Medium.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Medium.woff") format("woff");
}

@font-face {
    font-family: $default-font-family-light-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Light.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Light.woff") format("woff");
}

@font-face {
    font-family: $default-font-family-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Medium.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Medium.woff") format("woff");

    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $default-font-family-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Medium.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Medium.woff") format("woff");

    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: $default-font-family-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Regular.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $default-font-family-name;
    src: url("/sf/ui/assets/font/roboto/Roboto-Light.woff2") format("woff2"),
        url("/sf/ui/assets/font/roboto/Roboto-Light.woff") format("woff");

    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Nothing You Could Do";
    font-style: normal;
    font-weight: 400;
    src: local("Nothing You Could Do"), local("NothingYouCouldDo"),
        url(/sf/ui/assets/font/nothing-you-could-do/nothing-you-could-do.woff2)
            format("woff2");
    src: local("Nothing You Could Do"), local("NothingYouCouldDo"),
        url(/sf/ui/assets/font/nothing-you-could-do/nothing-you-could-do.ttf)
            format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Simplifile-Icons";
    src: url("/sf/ui/assets/font/simplifile-icons/Simplifile-Icons.eot?1s11zk");
    src: url("/sf/ui/assets/font/simplifile-icons/Simplifile-Icons.eot?1s11zk#iefix")
            format("embedded-opentype"),
        url("/sf/ui/assets/font/simplifile-icons/Simplifile-Icons.ttf?1s11zk")
            format("truetype"),
        url("/sf/ui/assets/font/simplifile-icons/Simplifile-Icons.woff?1s11zk")
            format("woff"),
        url("/sf/ui/assets/font/simplifile-icons/Simplifile-Icons.svg?1s11zk#Simplifile-Icons")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

@media print {
    @font-face {
        font-family: $default-font-family-regular-name;
        src: url("/sf/ui/assets/font/roboto/Roboto-Regular.woff2")
                format("woff2"),
            url("/sf/ui/assets/font/roboto/Roboto-Regular.woff") format("woff");
    }

    @font-face {
        font-family: $default-font-family-bold-name;
        src: url("/sf/ui/assets/font/roboto/Roboto-Medium.woff2")
                format("woff2"),
            url("/sf/ui/assets/font/roboto/Roboto-Medium.woff") format("woff");
    }

    @font-face {
        font-family: $default-font-family-light-name;
        src: url("/sf/ui/assets/font/roboto/Roboto-Light.woff2") format("woff2"),
            url("/sf/ui/assets/font/roboto/Roboto-Light.woff") format("woff");
    }
}
