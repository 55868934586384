@import "core";
@import "common/colors";

ngb-tooltip-window.tooltip {
    &.show {
        opacity: 1;
    }

    div.arrow {
        display: none;
    }

    div.tooltip-inner {
        border: 1px solid $white;
    }

    div.tooltip-arrow {
        display: none;
    }
}
