// This is the styling recommended by Dan for tables
// Just add 'class="sf-grid"' to your <table>
@import "colors";

$item-select-cell-border: #aeaeae;

.sf-grid {
    background: none;
    display: inline-block;

    &.sf-grid-full-width {
        display: table;
    }

    thead tr,
    .ui-grid-header .ui-grid-header-cell-row {
        //background-color: $tableHeader; // leave transparent!
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: $default-font-family-bold, sans-serif;
        color: $white;

        td,
        th,
        .ui-grid-header-cell {
            background-color: $dark9;
            color: $white;
            position: relative;
            line-height: 1em;
            vertical-align: middle;

            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;

            span,
            i {
                display: inline-block;
            }

            label {
                color: $white;
            }

            &.sf-grid-item-select-cell {
                label {
                    color: $white;
                }
            }
        }

        td.sortable {
            cursor: pointer;
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: $light5;
        }
        tr:nth-child(odd) {
            background-color: $light3;
        }
        tr.selected {
            background-color: $l-bg-interaction;
        }
        tr.new-row {
            font-weight: bold;
            font-family: $default-font-family-bold;
        }
        tr.pending {
            font-style: italic;
        }
    }

    td.sf-grid-cell {
        padding: 5px;
        border-top: none;
        line-height: 20px;
    }

    // Replace checkbox with Font Awesome check
    td.sf-grid-item-select-cell {
        border-right: solid 1px $item-select-cell-border;
        width: 30px;

        input[type="checkbox"] {
            display: none;
        }
        label {
            margin: 0;
            font-size: 20px;
            color: $interaction11;
        }
    }
}

// remove this section after the enable-readable-grid-headers live config is removed
&.enable-readable-grid-headers {
    .sf-grid {
        tr.header-row,
        thead tr {
            border: 1px solid $dark3;
            td {
                border: none;
            }
        }
        thead tr,
        .ui-grid-header .ui-grid-header-cell-row {
            color: $dark10;

            td,
            th,
            .ui-grid-header-cell {
                background-color: $light6;
                color: $dark10;
                font-size: 15px;
                fa-icon {
                    font-size: 15px;
                }
                label {
                    color: $dark10;
                }

                &.sf-grid-item-select-cell {
                    label {
                        color: $dark10;
                    }
                }
            }
            td.sortable {
                padding-top: 0px;
                padding-bottom: 0px;
                button.column-header {
                    width: 100%;
                    text-align: left;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                &:hover {
                    background-color: $interaction1;
                    button.column-header {
                        margin-top: -1.5px;
                    }
                    button.column-header:not(:has(*)) {
                        margin-top: -1.5px;
                    }
                    button.column-header:not(:has(*))::after {
                        font-family: "simplifile-grid";
                        content: "\e938";
                        color: $interaction11;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
