*[ngbdropdownmenu] {
    padding: 8px 0px 8px 0px;
    gap: 4px;
    border: solid 1px $dark2;
    border-radius: 5px;

    section {
        .action-header {
            padding: 0px 16px 0px 16px;
            font-weight: bold;
            color: $dark5;
            font-size: 14px;
            margin-bottom: 4px;
        }
    }

    .dropdown-item {
        color: $interaction11;
        font-size: 15px;
        padding-left: 12px;
        padding-right: 12px;
        user-select: none;
        outline: none;
        cursor: pointer;
        height: 32px;

        &:active,
        &.active {
            background-color: $interaction11;
            color: $white;

            &:hover,
            &.hover {
                background-color: $interaction12;
                color: $white;
            }
        }

        &:hover,
        &.hover {
            background-color: $interaction2;
            color: $interaction11;
        }

        &:disabled,
        &.disabled {
            color: $dark4;
        }

        fa-icon {
            display: inline-block;

            /* Equivalent to the FontAwesome fixed width class (.fa-fw) */
            width: 1.28571429em;
            text-align: center;
        }
    }
}
