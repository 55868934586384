@import "variables";
@import "functions";
@import "mixins";
@import "../bootstrap/variables";

@mixin checkbox-image($color: $white) {
    // FontAwesome solid check
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' fill='#{ encode-color($color) }' /%3E%3C/svg%3E");
}

@mixin radio-image($color: $white) {
    // Circle
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='#{ encode-color($color) }'/%3e%3c/svg%3e");
}

input[type="text"] {
    &::-ms-clear {
        display: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
    width: 13px;
    height: 13px;
    margin-top: 0.2em;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid $dark6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    &:checked {
        background-color: $interaction;
        border-color: $interaction;
    }

    &:focus-visible {
        outline: none;
    }

    &:disabled {
        border-color: $dark2;
        background-color: $dark1;
    }
}

input[type="checkbox"] {
    border-radius: 2px;

    &:checked {
        @include checkbox-image;

        &:disabled {
            @include checkbox-image($dark4);
        }
    }

    &:focus-visible {
        @include focus-outline($interaction);
    }
}

input[type="radio"] {
    border-radius: 50%;
    position: relative;

    &:checked {
        @include radio-image;

        &:disabled {
            @include radio-image($dark4);
        }
    }

    &:focus-visible {
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            bottom: -1px;
            right: -1px;
            border-radius: 2px;
            @include focus-outline($interaction);
        }
    }
}
