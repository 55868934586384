$text-active: #333333;
$text-headings: #666666;
$text-inactive: #727272;
$text-highlight: #fffbcc;

$white: #ffffff;
$black: #000000;

$success0: #f8fbf5;
$success1: #ebf4e2;
$success2: #daebca;
$success3: #c3dda9;
$success4: #abd186;
$success5: #9eca71;
$success6: #8ebf5c;
$success7: #7cb346;
$success8: #6eab32;
$success9: #5e9923;
$success10: #4b8513;
$success11: #407d04;
$success12: #3d7307;

$success: $success10;

$info0: #f6fcfc;
$info1: #ebf7f7;
$info2: #cdeff0;
$info3: #b0e0e2;
$info4: #95d6d8;
$info5: #80c9cc;
$info6: #64b1b3;
$info7: #52a3a6;
$info8: #409799;
$info9: #338a8c;
$info10: #358082;
$info11: #1b7a7d;
$info12: #2b6b6e;

$info: $info10;

$interaction0: #f4fafd;
$interaction1: #dbf1ff;
$interaction2: #bfe6ff;
$interaction3: #99d6ff;
$interaction4: #69c0fa;
$interaction5: #4eb0f2;
$interaction6: #31a0eb;
$interaction7: #1e94e3;
$interaction8: #0083db;
$interaction9: #007fd4;
$interaction10: #0074c2;
$interaction11: #0063a6;
$interaction12: #004d81;

$interaction: $interaction10;
$interaction-hover: darken($interaction, 15%);

$required0: #fef8f2;
$required1: #fdead9;
$required2: #fae1cb;
$required3: #f8cba6;
$required4: #f5b482;
$required5: #f49d5f;
$required6: #f2853d;
$required7: #f16f18;
$required8: #ef6000;
$required9: #db5800;
$required10: #c95100;
$required11: #bd4b00;
$required12: #af4600;

$required: $required10;

//$other0: #fbf2fe;
//$other1: #f7e4fd;
//$other2: #e4ccfc;
//$other3: #d8aef8;
//$other4: #d193f5;
//$other5: #cf7af4;
//$other6: #d061f2;
//$other7: #d548f1;
//$other8: #dc30ef;
//$other9: #d816df;
//$other10: #cf00c1;
//$other11: #bf00b5;
//$other12: #a8009d;
//
//$other: $other10;

$alert0: #fff5f6;
$alert1: #ffe2e5;
$alert2: #ffccd1;
$alert3: #ffa9b2;
$alert4: #fe8c97;
$alert5: #fe7986;
$alert6: #fe6574;
$alert7: #fe5263;
$alert8: #fe3f52;
$alert9: #ee2f42;
$alert10: #de1f32;
$alert11: #ce1f0f;
$alert12: #be0719;

$alert: $alert10;

$dark0: #f7f7f7;
$dark1: #e6e6e6;
$dark2: #cccccc;
$dark3: #b3b3b3;
$dark4: #999999;
$dark5: #888888;
$dark6: #767676;
$dark7: #666666;
$dark8: #555555;
$dark9: #444444;
$dark10: #333333;
$dark11: #252525;
$dark12: #151515;

$dark: $dark10;

$light0: #fcfcfc;
$light1: #fafafa;
$light2: #f9f9f9;
$light3: #f6f6f6;
$light4: #f2f2f2;
$light5: #f0f0f0;
$light6: #eeeeee;
$light7: #ececec;
$light8: #eaeaea;
$light9: #dadada;
$light10: #cacaca;
$light11: #bababa;
$light12: #aaaaaa;

$light: $light10;

// Light Background colors
$l-bg-success: $success0;
$l-bg-info: $info0;
$l-bg-interaction: $interaction0;
$l-bg-required: $required0;
//$l-bg-other: $other0;
$l-bg-alert: $alert0;
$l-bg-dark: $dark0;
$l-bg-light: $light0;

// Dark Background colors
$d-bg-success: $success2;
$d-bg-info: $info2;
$d-bg-interaction: $interaction2;
$d-bg-required: $required2;
//$d-bg-other: $other2;
$d-bg-alert: $alert2;
$d-bg-dark: $dark2;
$d-bg-light: $light2;

// Border colors
$border-success: $success6;
$border-info: $info6;
$border-interaction: $interaction6;
$border-required: $required6;
//$border-other: $other6;
$border-alert: $alert6;
$border-dark: $dark6;
$border-light: $light6;

// Text Colors
$text-success: $success10;
$text-info: $info10;
$text-interaction: $interaction10;
$text-required: $required10;
//$text-other: $other10;
$text-alert: $alert10;
$text-dark: $dark10;
$text-light: $light10;

$d-text-success: $success12;
$d-text-info: $info12;
$d-text-interaction: $interaction12;
$d-text-required: $required12;
//$d-text-other: $other12;
$d-text-alert: $alert12;
$d-text-dark: $dark12;
$d-text-light: $light12;

// Button Colors
$button-success: $success8;
$button-info: $info8;
$button-interaction: $interaction8;
$button-required: $required8;
//$button-other: $other8;
$button-alert: $alert8;
$button-dark: $dark8;
$button-light: $light8;

$button-hover-success: $success9;
$button-hover-info: $info9;
$button-hover-interaction: $interaction9;
$button-hover-required: $required9;
//$button-hover-other: $other9;
$button-hover-alert: $alert9;
$button-hover-dark: $dark9;
$button-hover-light: $light9;

$button-active-success: $success10;
$button-active-info: $info10;
$button-active-interaction: $interaction8;
$button-active-required: $required10;
//$button-active-other: $other10;
$button-active-alert: $alert10;
$button-active-dark: $dark10;
$button-active-light: $light10;

$button-disabled-success: $success3;
$button-disabled-info: $info3;
$button-disabled-interaction: $interaction3;
$button-disabled-required: $required3;
//$button-disabled-other: $other3;
$button-disabled-alert: $alert3;
$button-disabled-dark: $dark3;
$button-disabled-light: $light3;

// Icon colors
$icon-success: $success10;
$icon-info: $info10;
$icon-interaction: $interaction10;
$icon-required: $required10;
//$icon-other: $other10;
$icon-alert: $alert10;
$icon-dark: $dark10;
$icon-inactive: $light10;

// Heading colors
$heading-success: $success8;
$heading-info: $info8;
$heading-interaction: $interaction8;
$heading-required: $required8;
//$heading-other: $other8;
$heading-alert: $alert8;
$heading-dark: $dark8;
$heading-light: $light8;

// Base
$body: $light2;

// DocViewerIcons
$icon-docView-base: $interaction;
$docViewerLockBanner-bg: #fad82b;

$draft-text: $required7;
$draft-background: $required5;
$draft-detail-arrow: $required9;

$cancelled-text: $light1;
$cancelled-background: $light10;
$cancelled-detail-arrow: $light10;

$recorded-text: $success10;
$recorded-background: $success10;
$recorded-detail-arrow: $success12;

$billed-rejected-text: $light1;
$billed-rejected-background: $light10;
$billed-rejected-detail-arrow: $light10;

$sent-text: $info10;
$sent-background: $info6;
$sent-detail-arrow: $info12;

$received-text: $info10;
$received-background: $info8;
$received-detail-arrow: $info12;

$accepted-text: $info10;
$accepted-background: $info8;
$accepted-detail-arrow: $info12;

$rejected-text: $alert10;
$rejected-background: $alert8;
$rejected-detail-arrow: $alert12;

$ready-text: $interaction10;
$ready-background: $interaction8;
$ready-detail-arrow: $interaction12;

$maintenance-text: $dark8;
$maintenance-background: $dark6;
$maintenance-detail-arrow: $dark10;

$voided-text: $dark10;
$voided-background: $alert6;
$voided-detail-arrow: $alert6;

$removed-text: $dark4;
$removed-background: $dark2;
$removed-detail-arrow: $dark6;

$finalizing-text: $success12;
$finalizing-background: $success3;
$finalizing-detail-arrow: $success9;

$prepare-text: $required1;
$prepare-background: $required3;
$prepare-detail-arrow: $required5;
