@import "colors";
@import "fonts";

$divider-font-size: 20px;
$large-font-size: 19px;
$medium-font-size: 18px;
$default-font-size: 16px;
$input-font-size: 15px;
$label-font-size: 14px; // .9em
$small-font-size: $label-font-size;
$smaller-font-size: 13px;
$tiny-font-size: 12px;

$h1-font-size: 48px;
$h2-font-size: 28px;
$h3-font-size: 22px;
$h4-font-size: $default-font-size;
$h5-font-size: $input-font-size;
$h6-font-size: $small-font-size;

$panel-width: 180px;
$header-height: 80px;
$subheader-height: 40px;
$vertical-padding: 16px;
$horizontal-padding: 24px;

$input-btn-focus-width: 2px;
$btn-focus-width: 0.1rem;

$custom-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
$custom-select-bg-size: 16px 12px;

$banner-height: 36px;
$input-border-radius: 0.25rem;
