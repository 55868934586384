@import "../common/colors";

ngb-popover-window.popover {
    border-radius: 5px;
    border: 1px solid $dark2;

    &.bs-popover-bottom .arrow {
        //margin-left: -5px;
        margin: 0 0 0 -0.5rem;
    }
    &.bs-popover-bottom-right .arrow {
        //margin-right: -15px;
        right: 0.5em;
    }
}

ngb-popover-window.popover,
sf-popover {
    .popover-header {
        background: $light4;
        font-size: 13px;
        font-weight: bold;
        color: $dark12;
        height: 40px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        padding: 0 10px 0 10px;
        line-height: 40px;
    }
    .popover-body {
        background: white;
        font-size: 15px;
        color: $dark12;
        padding: 10px;
        border-radius: 10px;

        button.btn-link {
            white-space: nowrap;

            &.padding-left {
                padding-left: 1.75rem;
            }
        }
    }
}
