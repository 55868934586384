@import "common/fonts";

.login-styles {
    .login-container {
        margin: 68px auto 0 auto;
        width: 560px;
        .login-form {
            margin-top: 30px;
        }
        form.login-form .mb-3 input {
            border-radius: 4px 4px 4px 4px;
            height: 48px;
            font-size: 18px;
            padding-left: 12px;
        }
        .sf-logo-large {
            display: block;
            margin: 0 auto 0 auto;
        }
        .login-options {
            margin: 8px auto;
            text-align: center;
            &.back {
                margin-top: 16px;
            }
        }
        h1 {
            color: #666666 !important;
            font-size: 1.75em !important;
            position: relative;
            font-weight: normal;
            font-family: $default-font-family-bold, Arial, Helvetica, sans-serif;
            margin: 4px 0 16px;
            text-align: center;
        }
        p {
            text-align: center;
        }
        .username-link {
            position: absolute;
            right: -150px;
            top: 20px;
        }

        .input-wrapper {
            padding: 12px;
        }

        .terms-block {
            margin: 28px auto;
            font-size: 90%;
        }
    }

    div.input-area {
        width: 380px;
        margin: 0 auto;
        position: relative;

        label {
            color: #353535;
        }
    }

    form.login-form div.input-area fieldset input {
        &[type="text"],
        &[type="password"] {
            height: 48px;
            padding-left: 10px;
            font-size: 17px;
        }
    }

    div.pw-validator {
        border-radius: 4px 4px 4px 4px;
    }

    span.pw-validator-tooltip {
        position: absolute;
        left: 107%;
        top: -2px;
        width: 330px;
    }

    .sf-logo-large {
        display: block;
        width: 298px;
        margin: 0 auto 68px;
    }

    input {
        font-family: $default-font-family, Verdana, Tahoma, sans-serif;
    }

    form button[type="submit"] {
        margin: 0;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .sf-notification {
        /* fix an appearance difference with sf-notification here */
        .content {
            width: auto;
        }
        .title,
        .text,
        .content .title {
            display: inline-block !important;
        }
    }

    .subtitle {
        font-size: 11px;
    }

    label {
        position: relative;
        div {
            position: absolute;
            top: 3px;
            left: 1px;
            z-index: 3;
            font-size: 18px;
            padding: 10px;
            white-space: nowrap;
            color: #90918d;
        }
    }

    div.hidden {
        display: none;
    }

    button[disabled] {
        color: #ffffff;
    }

    input {
        &[type="submit"][disabled],
        &[type="button"][disabled] {
            color: #ffffff;
        }
    }

    button.disabled {
        color: #ffffff;
    }

    input {
        &[type="submit"].disabled,
        &[type="button"].disabled {
            color: #ffffff;
        }
    }

    // User Information Update Related CSS
    .update-container {
        margin: 68px auto 0 auto;
        width: 500px;
        h4,
        h2 {
            text-align: center;
            margin-bottom: 24px;
        }

        .user-info {
            color: #9b9c9a;
            text-align: center;
            position: relative;
            top: 12px;
        }

        .center-wrapper {
            margin: 0 auto;
            width: 410px;
        }

        #edit {
            float: left;
        }

        #confirm {
            float: right;
            *float: left;
        }

        #change {
            float: right;
            margin-top: 48px;
            margin-right: 86px;
        }

        .user-information {
            position: relative;
            left: 16px;
        }
    }

    .oldIEMessage {
        display: none;
    }
    .oldIE {
        .oldIEMessage {
            display: block !important;
        }
        .input-area,
        .login-options,
        .terms-block {
            display: none !important;
        }
    }
    .modal-dialog {
        h3 {
            font-size: 18px;
            color: #586670;
        }
        p {
            color: #586670;
            opacity: 0.8;
            filter: alpha(opacity = 80);
        }
    }
    .oldIEMessage a.bold {
        font-weight: bold;
    }
    // End User Information Update Related CSS
}
