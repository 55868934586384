@import "colors";

.table.sf-sortable-table {
    tr {
        &.draggable-mirror {
            background: $light1;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                box-shadow: 0 3px 12px rgba(0, 0, 0, 0.175);
                z-index: -1;
                animation: fade 0.1s ease both;
            }

            @keyframes fade {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }

        &.draggable--over,
        &.draggable-source--is-dragging {
            pointer-events: none;

            td {
                opacity: 0;
            }
        }

        &.disable-drag {
            .draggable-mirror {
                display: none;
            }
        }
    }
}
