@import "../common/core";

/*

Intellij Idea-like styling (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: $dark;
    background: $light0;
}

.hljs-subst,
.hljs-title {
    font-weight: normal;
    color: $dark;
}

.hljs-comment,
.hljs-quote {
    color: #808080;
    font-style: italic;
}

.hljs-meta {
    color: #808000;
}

.hljs-tag {
    background: $light3;
}

.hljs-section,
.hljs-name,
.hljs-literal,
.hljs-keyword,
.hljs-selector-tag,
.hljs-type,
.hljs-selector-id,
.hljs-selector-class {
    font-weight: bold;
    color: $success;
}

.hljs-attribute,
.hljs-number,
.hljs-regexp,
.hljs-link {
    font-weight: bold;
    color: $success;
}

.hljs-number,
.hljs-regexp,
.hljs-link {
    font-weight: normal;
}

.hljs-string {
    color: $required10;
    font-weight: bold;
}

.hljs-symbol,
.hljs-bullet,
.hljs-formula {
    color: $dark;
    background: #d0eded;
    font-style: italic;
}

.hljs-doctag {
    text-decoration: underline;
}

.hljs-variable,
.hljs-template-variable {
    color: $required8;
}

.hljs-addition {
    background: #baeeba;
}

.hljs-deletion {
    background: #ffc8bd;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-built_in {
    color: $interaction11;
}
