@import "variables";
@import "mixins";

@media (max-width: 600px) {
    header {
        display: none;
    }

    main {
        margin-left: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    html {
        max-width: 600px !important;
    }

    body {
        //		font-size: 20px!important;
    }

    main ul,
    ol {
        word-wrap: break-word;
    }

    section.sidebar {
        position: relative !important;
        height: auto !important;
        width: auto !important;
        display: block !important;

        nav .full {
            display: none !important;
            overflow: hidden !important;
        }

        nav .mobile {
            display: block !important;
        }

        .nav-title-container,
        .logo-container {
            display: none !important;
            overflow: hidden !important;
        }
    }
}

@media (min-width: 480px) {
    .pull-right-xs {
        float: right;
    }

    .text-end-xs {
        text-align: right;
    }

    .pull-left-xs {
        float: left;
    }

    .text-start-xs {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .pull-right-sm {
        float: right;
    }

    .text-end-sm {
        text-align: right;
    }

    .pull-left-sm {
        float: left;
    }

    .text-start-sm {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .pull-right-md {
        float: right;
    }

    .text-end-md {
        text-align: right;
    }

    .pull-left-md {
        float: left;
    }

    .text-start-md {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .pull-right-lg {
        float: right;
    }

    .text-end-lg {
        text-align: right;
    }

    .pull-left-lg {
        float: left;
    }

    .text-start-lg {
        text-align: left;
    }
}

section.sidebar,
.sidebar {
    width: $panel-width;
    //	display: inline-block;
    //	vertical-align: top;
    height: 100%;
    background-color: $dark11;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    &.collapsed {
        width: 30px;
    }

    .logo-container,
    .nav-title-container {
        background-color: $dark9;
        @include box-sizing;
        color: $light1;
    }

    .logo-container {
        height: $header-height;
        display: flex;
        justify-content: center;
        align-content: center;
        min-height: $header-height;
        flex-wrap: wrap;
    }

    .nav-title-container {
        display: table;
        vertical-align: middle;
        padding: 8px 14px 2px 14px;
        height: $subheader-height;

        .wrapper {
            display: table-cell;
            vertical-align: middle;
            cursor: pointer;
            font-size: 1.1em;
            color: $light1;

            span {
                &:hover {
                    text-decoration: underline;
                }
            }

            b {
                padding-left: 5em;
            }
        }
    }

    button.btn-link {
        align-self: flex-start;
        cursor: pointer;
        margin: 3px;
        padding: 4px;

        &.chevron-left {
            align-self: flex-end;
        }

        &.chevron-right {
            margin-top: auto;
        }

        fa-icon {
            color: $white;
        }
    }
}

.nav-panel {
    background-color: $dark11;
    display: flex;
    overflow-x: hidden;
    border: solid 1px $white;
    border-left: none;
    border-radius: 0px 4px 4px 0px;
    color: $white;
    padding: 1px;
}

.nav-panel-backdrop {
}

.header {
    position: relative;
    box-sizing: border-box;

    h1,
    h2 {
        margin: 0.75rem 0 0 0;
        font-size: $h2-font-size;
        font-weight: 400;
    }

    hr,
    .hr {
        margin: 0;
        width: 100%;
    }
}

.row {
    &.header-padding {
        margin-bottom: 2px;
        height: $header-height - $vertical-padding - 4px;
    }
}

ui-view {
    display: block;
}

main {
    //	display: inline-block;
    //	width: calc(#{"100% - 180px"});
    //	vertical-align: top;
    //	@include box-sizing;
    //	padding: $vertical-padding 0px;
    //	margin-left: $panel-width;
    height: 100%;
    position: relative;

    > .sub-navigation {
        sf-link-list {
            vertical-align: top;
            margin: 0 10px 0 -10px;
        }
    }

    .sf-border-spacer {
        height: 2px;

        &.top {
            margin-top: 15px;
            background: linear-gradient(
                to bottom,
                #dbdbdb 0%,
                rgba(0, 0, 0, 0.01) 80%,
                rgba(0, 0, 0, 0.01) 100%
            );
            height: 4px;

            hr {
                border-bottom: 0;
                border-top: 0;
            }
        }

        hr {
            margin: 0;
        }
    }

    .head-wrapper {
        height: 98px;
        overflow: hidden;
        position: relative;
        margin-left: -2em;
        padding-left: 2em;
        padding-right: 2em;

        .head-inner {
            position: relative;

            //			@include transition(top .3s);

            //			h2 {
            //				opacity: 0;
            //			}
            //
            //			h2.active {
            //				opacity: 1;
            //			}

            &.rejected {
                top: 0;
            }

            &.draft {
                top: -60px;
            }

            &.ready {
                top: -115px;
            }

            &.sent {
                top: -175px;
            }

            &.recorded {
                top: -240px;
            }
        }
    }

    .header {
        h1,
        h2 {
            display: inline-block;
        }

        i {
            color: $heading-dark;
            margin-right: 8px;
            position: relative;
            bottom: 3px;
        }

        a {
            i {
                color: $button-interaction;
                margin-right: 0;
            }
        }

        .profile-wrapper {
            float: right;
            margin-top: 28px;

            i {
                font-size: 24px;
                color: #45535d;
            }
        }
    }

    hr.scroll-top {
        border-top-color: $dark2;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    &.sf-main-padding {
        padding: $vertical-padding $horizontal-padding;
    }
}

.content-block {
    background-color: $light1;
    @include box-sizing;
    margin-top: 2em;
    border: 1px solid $light4;
    border-radius: 4px;

    > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
    }
}

.block-padding {
    padding: 2em;
}

.block {
    @include box-sizing;
    margin-top: 2em;

    > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
    }
}

.auto-grid {
    width: 25%;
    float: left;
}

.margin-bottom {
    margin-bottom: 1em;
}

.sf-fixed {
    top: 0;
    position: fixed;
    background-color: $light2;
    padding: 9px 0 0 32px;
    margin-left: -32px;
    width: calc(#{"100% - 212px"});
    z-index: 100;
}

.sf-container {
    padding: 0;
    margin: 0;
}

.sf-fixed-nav,
.sf-main-layout .sf-row {
    &.hide-overflow {
        overflow: hidden;
    }
}

.sf-main-layout {
    height: 100%;
    main {
        display: flex;
        flex-direction: column;
    }
    .sf-row,
    .sf-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 0;
    }
}

.sf-main-no-padding {
    .sf-main {
        padding: 0;
    }
}

.sf-fixed-nav {
    max-width: $panel-width;
    height: 100%;
}

.sf-main-layout .sf-row {
    height: 100%;
    overflow: auto;
    box-sizing: content-box;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.sf-main-layout-overflow-hidden {
    .sf-row,
    .sf-main {
        overflow: hidden;
    }
}

.sf-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $vertical-padding $horizontal-padding;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.container-fluid {
    &.sf-main-layout {
        padding-left: 0;
        padding-right: 0;
    }
}

.add-item-button {
    background-color: transparent;
    border: 2px solid $interaction8;
    border-radius: 15px;
    color: $interaction;
    cursor: pointer;
    padding: 4px 8px;

    &:hover {
        background-color: $interaction1;
        border-color: $interaction9;
        color: $interaction9;
    }

    i {
        margin-right: 16px;
    }
}

.row.single-item {
    align-items: center;
    border-bottom: 1px solid $light6;
    display: flex;
    min-height: 36px;
    position: relative;

    .non-input {
        margin-left: 9px; // Adjust for border and padding on inputs
    }

    &.separator {
        background-color: $light4;
    }

    &:hover:not(.separator) {
        background-color: $light3;
    }

    .fa-stack {
        font-size: $label-font-size;

        &.required {
            color: $required6;
        }
    }

    input:not([type="radio"]):not([type="checkbox"]),
    textarea {
        margin-bottom: inherit;

        //&:not(:focus):not(.ng-invalid) {
        &:not(:focus) {
            @include mask-input;

            &:hover {
                @include mask-input-hover;
            }
        }

        &[readonly="readonly"] {
            &:focus,
            &:not(:focus) {
                color: $dark10;
                cursor: inherit;
            }
        }
    }

    .ui-select-container:not(.ng-invalid-required) {
        a.select2-choice.ui-select-match {
            padding-left: 8px;

            .select2-chosen {
                color: $interaction11;
            }
        }

        &:not(.select2-container-active) a {
            @include mask-input;

            &:hover {
                @include mask-input-hover;
            }
        }
    }

    sf-select {
        .sf-select-field {
            padding-left: 8px;
        }
        .sf-select-two-field:not(:focus) {
            @include mask-input;
            font-family: $default-font-family;
            font-size: $input-font-size;

            &:hover {
                @include mask-input-hover;
            }

            &.readonly {
                color: $dark10;
                cursor: inherit;
            }
        }
        .sf-select:not(:focus):not(.ng-invalid) {
            .sf-select-field {
                @include mask-input;
                font-family: $default-font-family;
                font-size: $input-font-size;

                &:hover {
                    @include mask-input-hover;
                }
            }

            &.readonly {
                .sf-select-field {
                    color: $dark10;
                    cursor: inherit;
                }
            }
        }
    }

    sf-currency-input:not([readonly="readonly"]) .currency-wrapper {
        color: $interaction10;
    }

    textarea {
        width: 100%;
        margin-bottom: -5px;
    }

    sf-date-picker {
        margin-bottom: -5px;
    }
}
