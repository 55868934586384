/* @import "bootstrap/core"; */
@import "../../../../node_modules/bootstrap/scss/functions";
@import "bootstrap/variables";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "common/mixins";
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/utilities";

.sf-angular {
    @import "../../../../node_modules/bootstrap/scss/reboot";
    @import "bootstrap/reboot";
    @import "../../../../node_modules/bootstrap/scss/type";
    @import "bootstrap/type";
    @import "../../../../node_modules/bootstrap/scss/images";
    @import "../../../../node_modules/bootstrap/scss/containers";
    @import "../../../../node_modules/bootstrap/scss/grid";
    @import "../../../../node_modules/bootstrap/scss/helpers";
    @import "../../../../node_modules/bootstrap/scss/utilities/api";
    @import "../../../../node_modules/bootstrap/scss/tables";
    @import "bootstrap/tables";
    @import "../../../../node_modules/bootstrap/scss/forms";
    @import "bootstrap/forms";
    @import "../../../../node_modules/bootstrap/scss/buttons";
    @import "bootstrap/buttons";
    @import "../../../../node_modules/bootstrap/scss/transitions";
    @import "../../../../node_modules/bootstrap/scss/dropdown";
    @import "../../../../node_modules/bootstrap/scss/button-group";
    @import "bootstrap/button-group";
    @import "../../../../node_modules/bootstrap/scss/forms/input-group";
    @import "bootstrap/input-group";
    @import "../../../../node_modules/bootstrap/scss/nav";
    @import "../../../../node_modules/bootstrap/scss/navbar";
    @import "../../../../node_modules/bootstrap/scss/card";
    @import "../../../../node_modules/bootstrap/scss/breadcrumb";
    @import "../../../../node_modules/bootstrap/scss/pagination";
    @import "../../../../node_modules/bootstrap/scss/badge";
    @import "../../../../node_modules/bootstrap/scss/alert";
    @import "bootstrap/alert";
    @import "../../../../node_modules/bootstrap/scss/progress";
    @import "../../../../node_modules/bootstrap/scss/list-group";
    @import "../../../../node_modules/bootstrap/scss/close";
    @import "../../../../node_modules/bootstrap/scss/modal";
    @import "../../../../node_modules/bootstrap/scss/tooltip";
    @import "../../../../node_modules/bootstrap/scss/popover";
    @import "bootstrap/popover";
    @import "../../../../node_modules/bootstrap/scss/carousel";
}
