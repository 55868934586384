@use "sass:math";
@import "core";
@import "../common/fonts";
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
//
// Imports
//
@import "../common/_colors";

// Prefix
/* $prefix: "sf"; */

//
// Color system
//

$white: $white;
$gray-100: $dark0;
$gray-200: $dark1;
$gray-300: $dark2;
$gray-400: $dark3;
$gray-500: $dark5;
$gray-600: $dark6;
$gray-650: $dark7;
$gray-700: $dark8;
$gray-800: $dark9;
$gray-900: $dark11;
$black: $black;

/* $grays: () !default; */
/* // stylelint-disable-next-line scss/dollar-variable-default */
/* $grays: map-merge( */
/*     ( */
/*         "100": $gray-100, */
/*         "200": $gray-200, */
/*         "300": $gray-300, */
/*         "400": $gray-400, */
/*         "500": $gray-500, */
/*         "600": $gray-600, */
/*         "700": $gray-700, */
/*         "800": $gray-800, */
/*         "900": $gray-900 */
/*     ), */
/*     $grays */
/* ); */

$primary: $interaction10;
$secondary: $light4;
$success: $green;
$info: $dark10;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "required": $required6
    ),
    $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

$blue: $interaction;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: $alert;
$orange: $required;
$yellow: #ffc107;
$green: $success;
$teal: #20c997;
$cyan: $info;

/* $colors: () !default; */
/* // stylelint-disable-next-line scss/dollar-variable-default */
/* $colors: map-merge( */
/*     ( */
/*         "blue": $blue, */
/*         "indigo": $indigo, */
/*         "purple": $purple, */
/*         "pink": $pink, */
/*         "red": $red, */
/*         "orange": $orange, */
/*         "yellow": $yellow, */
/*         "green": $green, */
/*         "teal": $teal, */
/*         "cyan": $cyan, */
/*         "white": $white, */
/*         "gray": $gray-600, */
/*         "gray-dark": $gray-800 */
/*     ), */
/*     $colors */
/* ); */

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

/* $enable-caret: true !default; */
/* $enable-rounded: true !default; */
/* $enable-shadows: false !default; */
/* $enable-gradients: false !default; */
/* $enable-transitions: true !default; */
/* $enable-prefers-reduced-motion-media-query: true !default; */
/* $enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS */
/* $enable-grid-classes: true !default; */
/* $enable-button-pointers: true !default; */
/* $enable-print-styles: true !default; */
/* $enable-responsive-font-sizes: false !default; */
/* $enable-validation-icons: true !default; */
/* $enable-deprecation-messages: true !default; */
$enable-negative-margins: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

/* $spacer: 1rem !default; */
/* $spacers: () !default; */
/* // stylelint-disable-next-line scss/dollar-variable-default */
/* $spacers: map-merge( */
/*     ( */
/*         0: 0, */
/*         1: ( */
/*             $spacer * 0.25 */
/*         ), */
/*         2: ( */
/*             $spacer * 0.5 */
/*         ), */
/*         3: $spacer, */
/*         4: ( */
/*             $spacer * 1.5 */
/*         ), */
/*         5: ( */
/*             $spacer * 3 */
/*         ) */
/*     ), */
/*     $spacers */
/* ); */

// This variable affects the `.h-*` and `.w-*` classes.
/* $sizes: () !default; */
/* // stylelint-disable-next-line scss/dollar-variable-default */
/* $sizes: map-merge( */
/*     ( */
/*         25: 25%, */
/*         50: 50%, */
/*         75: 75%, */
/*         100: 100%, */
/*         auto: auto */
/*     ), */
/*     $sizes */
/* ); */

// Body
//
// Settings for the `<body>` element.

$body-bg: $light2;
$body-color: $text-active;

// Links
//
// Style anchor elements.

$link-color: $interaction11;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
/* $emphasized-link-hover-darken-percentage: 15%; */

// Paragraphs
//
// Style p element.

/* $paragraph-margin-bottom: 1rem !default; */

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.25 !default;
$line-height-sm: 1.25 !default;

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;

/* $rounded-pill:                50rem !default; */

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

/* $component-active-color: $white !default; */
/* $component-active-bg: $primary !default; */

/* $caret-width: 0.3em !default; */
/* $caret-vertical-align: $caret-width * 0.85 !default; */
/* $caret-spacing: $caret-width * 0.85 !default; */

/* $transition-base: all 0.2s ease-in-out !default; */
/* $transition-fade: opacity 0.15s linear !default; */
/* $transition-collapse: height 0.35s ease !default; */

/* $embed-responsive-aspect-ratios: () !default; */
/* // stylelint-disable-next-line scss/dollar-variable-default */
/* $embed-responsive-aspect-ratios: join( */
/*     ((21 9), (16 9), (4 3), (1 1)), */
/*     $embed-responsive-aspect-ratios */
/* ); */

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: $default-font-family;
/* $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, */
/*     "Liberation Mono", "Courier New", monospace !default; */
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
/* $font-size-lg: ($font-size-base * 1.25) !default; */
/* $font-size-sm: ($font-size-base * 0.875) !default; */

/* $fw-lighter: lighter !default; */
/* $fw-light: 300 !default; */
/* $fw-normal: 400 !default; */
/* $fw-bold: 500 !default; */
/* $fw-bolder: bolder !default; */

/* $fw-base: $fw-normal !default; */
$line-height-base: 1.25;

/* $h1-font-size: $font-size-base * 2.5 !default; */
/* $h2-font-size: $font-size-base * 2 !default; */
/* $h3-font-size: $font-size-base * 1.75 !default; */
/* $h4-font-size: $font-size-base * 1.5 !default; */
/* $h5-font-size: $font-size-base * 1.25 !default; */
/* $h6-font-size: $font-size-base !default; */

/* $headings-margin-bottom: math.div($spacer, 2) !default; */
/* $headings-font-family: null !default; */
/* $headings-font-weight: 500 !default; */
/* $headings-line-height: 1.2 !default; */
$headings-color: $text-headings;

/* $display1-size: 6rem !default; */
/* $display2-size: 5.5rem !default; */
/* $display3-size: 4.5rem !default; */
/* $display4-size: 3.5rem !default; */
/**/
/* $display1-weight: 300 !default; */
/* $display2-weight: 300 !default; */
/* $display3-weight: 300 !default; */
/* $display4-weight: 300 !default; */
/* $display-line-height: $headings-line-height !default; */

/* $lead-font-size: ($font-size-base * 1.25) !default; */
/* $lead-font-weight: 300 !default; */

$small-font-size: 80% !default;

/* $text-muted: $gray-600 !default; */

/* $blockquote-small-color: $gray-600 !default; */
/* $blockquote-small-font-size: $small-font-size !default; */
/* $blockquote-font-size: ($font-size-base * 1.25) !default; */

/* $hr-border-color:               rgba($black, 0.1); */
/* $hr-border-width: $border-width !default; */

/* $dt-font-weight:                $font-weight-bold; */
/* $mark-padding:                  0.2em; */

/* $kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default; */
/* $nested-kbd-font-weight: $fw-bold !default; */

/* $list-inline-padding: 0.5rem !default; */

$mark-bg: #fcf8e3 !default;

/* $hr-margin-y: $spacer !default; */

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

/* $table-color: $body-color !default; */
$table-bg: null;
$table-accent-bg: rgba($black, 0.05);
/* $table-hover-color: $table-color !default; */
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;

/* $table-border-width: $border-width !default; */
/* $table-border-color: $border-color !default; */

/* $table-head-bg: $gray-200 !default; */
/* $table-head-color: $gray-700 !default; */

/* $table-dark-color: $white !default; */
/* $table-dark-bg: $dark !default; */
/* $table-dark-accent-bg: rgba($white, 0.05) !default; */
/* $table-dark-hover-color: $table-dark-color !default; */
/* $table-dark-hover-bg: rgba($white, 0.075) !default; */
/* $table-dark-border-color: lighten($table-dark-bg, 7.5%) !default; */
/* $table-dark-color: $white !default; */

/* $table-striped-order: odd !default; */

/* $table-caption-color: $text-muted !default; */

/* $table-bg-level: -9 !default; */
/* $table-border-level: -6 !default; */

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.25rem;
$input-btn-padding-x: 1rem;
/* $input-btn-font-family:             null !default; */
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base + 0.3625;

$input-btn-focus-width: 0.1rem;
/* $input-btn-focus-color:             rgba($component-active-bg, 0.25); */
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $interaction1;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
/* $input-btn-font-size-sm: $font-size-sm !default; */
/* $input-btn-line-height-sm: $line-height-sm !default; */

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
/* $input-btn-font-size-lg: $font-size-lg !default; */
/* $input-btn-line-height-lg: $line-height-lg !default; */
/**/
$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
/* $btn-font-family: $input-btn-font-family !default; */
/* $btn-font-size: $input-btn-font-size !default; */
$btn-line-height: $input-btn-line-height;

/* $btn-padding-y-sm: $input-btn-padding-y-sm !default; */
/* $btn-padding-x-sm: $input-btn-padding-x-sm !default; */
/* $btn-font-size-sm: $input-btn-font-size-sm !default; */
/* $btn-line-height-sm: $input-btn-line-height-sm !default; */

/* $btn-padding-y-lg: $input-btn-padding-y-lg !default; */
/* $btn-padding-x-lg: $input-btn-padding-x-lg !default; */
/* $btn-font-size-lg: $input-btn-font-size-lg !default; */
/* $btn-line-height-lg: $input-btn-line-height-lg !default; */

/* $btn-border-width: $input-btn-border-width !default; */

$btn-hover-bg: $interaction10;
$btn-hover-border-color: $interaction10;

$btn-font-weight: $font-weight-bold;
$btn-color: $white;
$btn-disabled-color: $text-inactive;
$btn-disabled-bg: $light4;
$btn-disabled-border-color: $light10;
$disabled-background: $light4;
/* $btn-box-shadow: inset 0 2px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default; */
/* $btn-focus-width: $input-btn-focus-width !default; */
/* $btn-focus-box-shadow: $input-btn-focus-box-shadow !default; */
/* $btn-disabled-opacity: 0.65 !default; */
/* $btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default; */

/* $btn-link-disabled-color: $gray-600 !default; */

/* $btn-block-spacing-y: 0.5rem !default; */

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
/* $btn-border-radius-lg: $border-radius-lg !default; */
/* $btn-border-radius-sm: $border-radius-sm !default; */

/* $btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, */
/*     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default; */

// Forms

$label-margin-bottom: 0.5rem;

$input-padding-y: 0.5rem;
$input-padding-x: 0.5rem;
/* $input-font-family: $input-btn-font-family !default; */
$input-font-size: $input-btn-font-size;
/* $input-font-weight: $fw-base !default; */
$input-line-height: $input-btn-line-height;

/* $input-padding-y-sm: $input-btn-padding-y-sm !default; */
/* $input-padding-x-sm: $input-btn-padding-x-sm !default; */
/* $input-font-size-sm: $input-btn-font-size-sm !default; */
/* $input-line-height-sm: $input-btn-line-height-sm !default; */

/* $input-padding-y-lg: $input-btn-padding-y-lg !default; */
/* $input-padding-x-lg: $input-btn-padding-x-lg !default; */
/* $input-font-size-lg: $input-btn-font-size-lg !default; */
/* $input-line-height-lg: $input-btn-line-height-lg !default; */

$input-bg: $white;
/* $input-disabled-bg: $gray-200 !default; */

$input-color: $gray-700;
$input-border-color: $light9;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 3px $light1;

/* $input-border-radius: $border-radius !default; */
/* $input-border-radius-lg: $border-radius-lg !default; */
/* $input-border-radius-sm: $border-radius-sm !default; */

$input-focus-bg: $interaction0;
$input-focus-border-color: $interaction6;
/* $input-focus-color: $input-color !default; */
/* $input-focus-width: $input-btn-focus-width !default; */
/* $input-focus-box-shadow: $input-btn-focus-box-shadow !default; */

/* $input-placeholder-color: $gray-600 !default; */
/* $input-plaintext-color: $body-color !default; */

$input-height-border: $input-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height) +
    ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

/* $form-text-margin-top: 0.25rem !default; */

/* $form-check-input-gutter: 1.25rem !default; */
/* $form-check-input-margin-y: 0.1rem !default; */
/* $form-check-input-margin-x: 0.25rem !default; */

/* $form-check-inline-margin-x: 0.75rem !default; */
/* $form-check-inline-input-margin-x: 0.3125rem !default; */

/* $form-grid-gutter-width: 10px !default; */

$input-group-addon-color: $text-active !default;

// Form validation

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// Navs

// Navbar

// Dropdowns
//
// Dropdown menu container and contents.

// Pagination

// Jumbotron

// Cards

// Tooltips

$tooltip-bg: $text-active;

// Popovers

// Badges

// Modals

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-padding-x: 12px;
$alert-padding-y: 8px;

// Progress bars

// List group

// Image thumbnails

// Figures

// Breadcrumbs

// Carousel

// Close

// Code

// Utilities

// Printing

/* @import "../../../../../node_modules/bootstrap/scss/variables"; */
