@use "sass:math";
@import "colors";
@import "variables";

@mixin text-shadow-light($color: $light0) {
    text-shadow: 0px 1px 0px $color;
}

@mixin box-sizing($size: border-box) {
    box-sizing: $size;
    -moz-box-sizing: $size;
}

@mixin text-shadow-dark($color: rgba(0, 0, 0, 0.3)) {
    text-shadow: 0px -1px 0px $color;
}

@mixin triangle-left($color: white, $size: 10px) {
    border-color: transparent $color transparent transparent;
    border-style: solid;
    border-width: $size $size $size 0px;
    height: 0px;
    width: 0px;
}

@mixin triangle-right($color: white, $size: 10px) {
    border-color: transparent transparent transparent $color;
    border-style: solid;
    border-width: $size 0px $size $size;
    height: 0px;
    width: 0px;
}

@mixin triangle-top($color: white, $width: 20px, $height: 10px) {
    border-color: transparent transparent $color transparent;
    border-style: solid;
    border-width: 0 math.div($width, 2) $height math.div($width, 2);
    height: 0;
    width: 0;
}

@mixin triangle-bottom($color: white, $width: 20px, $height: 10px) {
    border-color: $color transparent transparent transparent;
    border-style: solid;
    border-width: $height math.div($width, 2) 0 math.div($width, 2);
    height: 0;
    width: 0;
}

@mixin box-glow($color: black, $size: 10px) {
    box-shadow: 0 0 $size $color;
    -webkit-box-shadow: 0 0 $size $color;
    -moz-box-shadow: 0 0 $size $color;
}

@mixin dark-scroll() {
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background-color: #dddddd;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $text-inactive;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #bbbbbb;
    }
}

@mixin sf-scroll-nav() {
    overflow-y: auto;
    border-collapse: collapse;

    &::-webkit-scrollbar {
        height: 16px;
        overflow: visible;
        width: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark10;
        background-clip: padding-box;
        border: solid transparent;
        border-width: 1px 1px 1px 6px;
        min-height: 28px;
        padding: 100px 0 0;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
            inset 0 -1px 0 rgba(0, 0, 0, 0.07);
        -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
            inset 0 -1px 0 rgba(0, 0, 0, 0.07);
        box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
            inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    }

    &::-webkit-scrollbar-button {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        background-clip: padding-box;
        border: solid transparent;
        border-width: 0 0 0 4px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

@mixin transition($arguments...) {
    -webkit-transition: $arguments;
    -moz-transition: $arguments;
    -ms-transition: $arguments;
    -o-transition: $arguments;
    transition: $arguments;
}

@mixin text-button() {
    color: $button-interaction;
    cursor: pointer;

    &:hover {
        color: $button-hover-interaction;
    }

    &:active {
        color: $button-active-interaction;
    }

    &[disabled],
    &.disabled,
    &.disabled a {
        color: $button-disabled-interaction;
        cursor: default;
        opacity: 1;
        text-decoration: none;
    }
}

@mixin user-select($arguments...) {
    -ms-user-select: $arguments;
    -moz-user-select: $arguments;
    -webkit-user-select: $arguments;
    user-select: $arguments;
}

@mixin TooltipColor($tooltip-bg-color) {
    + .tooltip .tooltip-inner {
        background-color: $tooltip-bg-color;
    }
    &.tooltip .tooltip-inner {
        background-color: $tooltip-bg-color;
    }
    + .tooltip.bs-tooltip-top .arrow::before {
        border-top-color: $tooltip-bg-color;
    }
    &.tooltip.bs-tooltip-top .arrow::before {
        border-top-color: $tooltip-bg-color;
    }
    + .tooltip.bs-tooltip-right .arrow::before {
        border-right-color: $tooltip-bg-color;
    }
    &.tooltip.bs-tooltip-right .arrow::before {
        border-right-color: $tooltip-bg-color;
    }
    + .tooltip.bs-tooltip-left .arrow::before {
        border-left-color: $tooltip-bg-color;
    }
    &.tooltip.bs-tooltip-left .arrow::before {
        border-left-color: $tooltip-bg-color;
    }
    + .tooltip.bs-tooltip-bottom .arrow::before {
        border-bottom-color: $tooltip-bg-color;
    }
    &.tooltip.bs-tooltip-bottom .arrow::before {
        border-bottom-color: $tooltip-bg-color;
    }
}

@mixin action-transition() {
    transition: all 0.2s ease-in-out;
}

// Provides the "Loan Info" format styling
@mixin mask-input() {
    background-color: transparent;
    border-color: transparent;
    color: $interaction10;
}

@mixin mask-input-hover() {
    background-color: $light1;
    border-color: $light3;
}

.icon-interaction {
    color: $interaction;
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root("::-webkit-input-placeholder") {
        @content;
    }

    @include optional-at-root(":-moz-placeholder") {
        @content;
    }

    @include optional-at-root("::-moz-placeholder") {
        @content;
    }

    @include optional-at-root(":-ms-input-placeholder") {
        @content;
    }

    @include optional-at-root("::placeholder") {
        @content;
    }
}

@mixin show-edit-icon($opacity: 1) {
    &:after {
        color: $interaction10;
        // This is the Pencil icon in the interaction color
        content: "\f040";
        font: normal normal normal 14px/1 "Simplifile-Icons";
        display: inline-block;
        width: 14px;
        height: 14px;

        margin-left: 4px;
        opacity: $opacity;
        position: absolute;
    }
}

@mixin focus-outline($inside: $white, $outside: $interaction) {
    box-shadow: 0 0 0 $btn-focus-width $inside,
        0 0 0 $btn-focus-width * 2 $outside;
}

@mixin focus-anchor-outline($outside: $interaction) {
    outline-style: solid;
    outline-color: $outside;
    outline-width: 1px;
    border-radius: 0.25rem;
}
