// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

/* .input-group { */
/*     position: relative; */
/*     display: flex; */
/*     flex-wrap: wrap; // For form validation feedback */
/*     align-items: stretch; */
/*     width: 100%; */
/**/
/*     > .form-control, */
/*     > .form-control-plaintext, */
/*     > .form-select, */
/*     > .form-floating { */
/*         position: relative; // For focus state's z-index */
/*         flex: 1 1 auto; */
/*         // Add width 1% and flex-basis auto to ensure that button will not wrap out */
/*         // the column. Applies to IE Edge+ and Firefox. Chrome does not require this. */
/*         width: 1%; */
/*         margin-bottom: 0; */
/**/
/*         + .form-control, */
/*         + .form-select, */
/*         + .form-floating { */
/*             margin-left: -$input-border-width; */
/*         } */
/*     } */
/**/
/*     // Bring the "active" form control to the top of surrounding elements */
/*     > .form-control:focus, */
/*     > .form-select:focus, */
/*     > .form-floating:focus-within { */
/*         z-index: 3; */
/*     } */
/**/
/*     // Bring the custom file input above the label */
/*     > .custom-file .custom-file-input:focus { */
/*         z-index: 4; */
/*     } */
/**/
/*     > .form-control, */
/*     > .custom-select { */
/*         &:not(:last-child) { */
/*             border-top-right-radius: 0px; */
/*             border-bottom-right-radius: 0px; */
/*         } */
/**/
/*         &:not(:first-child) { */
/*             border-top-left-radius: 0px; */
/*             border-bottom-left-radius: 0px; */
/*         } */
/*     } */
/**/
/*     // Custom file inputs have more complex markup, thus requiring different */
/*     // border-radius overrides. */
/*     > .custom-file { */
/*         display: flex; */
/*         align-items: center; */
/**/
/*         &:not(:last-child) .custom-file-label, */
/*         &:not(:last-child) .custom-file-label::after { */
/*             border-top-right-radius: 0px; */
/*             border-bottom-right-radius: 0px; */
/*         } */
/**/
/*         &:not(:first-child) .custom-file-label { */
/*             border-top-left-radius: 0px; */
/*             border-bottom-left-radius: 0px; */
/*         } */
/*     } */
/* } */

// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

/* .input-group-prepend, */
/* .input-group-append { */
/*     display: flex; */
/**/
/*     // Ensure buttons are always above inputs for more visually pleasing borders. */
/*     // This isn't needed for `.input-group-text` since it shares the same border-color */
/*     // as our inputs. */
/*     .btn { */
/*         position: relative; */
/*         z-index: 2; */
/*         &:focus { */
/*             z-index: 3; */
/*         } */
/*     } */
/**/
/*     .btn + .btn, */
/*     .btn + .input-group-text, */
/*     .input-group-text + .input-group-text, */
/*     .input-group-text + .btn { */
/*         margin-left: -$input-border-width; */
/*     } */
/* } */
/**/
/* .input-group-prepend { */
/*     margin-right: -$input-border-width; */
/* } */
/**/
/* .input-group-append { */
/*     margin-left: -$input-border-width; */
/* } */

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
    /* display: flex; */
    /* align-items: center; */
    padding: $input-padding-y - 0.25rem $input-padding-x - 0.25rem;
    /* margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom */
    /* @include font-size($input-font-size); // Match inputs */
    /* font-weight: $font-weight-normal; */
    /* line-height: $input-line-height; */
    /* color: $input-group-addon-color; */
    /* text-align: center; */
    /* white-space: nowrap; */
    /* background-color: $input-group-addon-bg; */
    /* border: $input-border-width solid $input-group-addon-border-color; */
    /* @include border-radius($input-border-radius); */
    /**/
    /* // Nuke default margins from checkboxes and radios to vertically center within. */
    /* input[type="radio"], */
    /* input[type="checkbox"] { */
    /*     margin-top: 0; */
    /* } */
}

// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

/* .input-group-lg > .form-control:not(textarea), */
/* .input-group-lg > .custom-select { */
/*     height: $input-height-lg; */
/* } */
/**/
/* .input-group-lg > .form-control, */
/* .input-group-lg > .custom-select, */
/* .input-group-lg > .input-group-prepend > .input-group-text, */
/* .input-group-lg > .input-group-append > .input-group-text, */
/* .input-group-lg > .input-group-prepend > .btn, */
/* .input-group-lg > .input-group-append > .btn { */
/*     padding: $input-padding-y-lg $input-padding-x-lg; */
/*     @include font-size($input-font-size-lg); */
/*     line-height: $input-line-height; */
/*     @include border-radius($input-border-radius-lg); */
/* } */
/**/
/* .input-group-sm > .form-control:not(textarea), */
/* .input-group-sm > .custom-select { */
/*     height: $input-height-sm; */
/* } */
/**/
/* .input-group-sm > .form-control, */
/* .input-group-sm > .form-select, */
/* .input-group-sm > .input-group-prepend > .input-group-text, */
/* .input-group-sm > .input-group-append > .input-group-text, */
/* .input-group-sm > .input-group-prepend > .btn, */
/* .input-group-sm > .input-group-append > .btn { */
/*     padding: $input-padding-y-sm $input-padding-x-sm; */
/*     @include font-size($input-font-size-sm); */
/*     line-height: $input-line-height-sm; */
/*     @include border-radius($input-border-radius-sm); */
/* } */
/**/
/* .input-group-lg > .form-select, */
/* .input-group-sm > .form-select { */
/*     padding-right: $form-select-padding-x + $form-select-indicator-padding; */
/* } */

// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.input-group-prepend,
.input-group-append {
    display: flex;

    // Ensure buttons are always above inputs for more visually pleasing borders.
    // This isn't needed for `.input-group-text` since it shares the same border-color
    // as our inputs.
    .btn {
        position: relative;
        z-index: 2;

        &:focus {
            z-index: 3;
        }
    }

    .btn + .btn,
    .btn + .input-group-text,
    .input-group-text + .input-group-text,
    .input-group-text + .btn {
        margin-left: -$input-border-width;
    }
}

/* .input-group > .input-group-prepend > .btn, */
/* .input-group > .input-group-prepend > .input-group-text, */
/* .input-group > .input-group-append:not(:last-child) > .btn, */
/* .input-group > .input-group-append:not(:last-child) > .input-group-text, */
/* .input-group */
/*     > .input-group-append:last-child */
/*     > .btn:not(:last-child):not(.dropdown-toggle), */
/* .input-group */
/*     > .input-group-append:last-child */
/*     > .input-group-text:not(:last-child) { */
/*     border-top-right-radius: 0px; */
/*     border-bottom-right-radius: 0px; */
/* } */
/**/
/* .input-group > .input-group-append > .btn, */
/* .input-group > .input-group-append > .input-group-text, */
/* .input-group > .input-group-prepend:not(:first-child) > .btn, */
/* .input-group > .input-group-prepend:not(:first-child) > .input-group-text, */
/* .input-group > .input-group-prepend:first-child > .btn:not(:first-child), */
/* .input-group */
/*     > .input-group-prepend:first-child */
/*     > .input-group-text:not(:first-child) { */
/*     border-top-left-radius: 0px; */
/*     border-bottom-left-radius: 0px; */
/* } */
