@import "../common/colors";
@import "../common/mixins";
@import "../bootstrap/variables";
/* @import "../common/variables"; */
// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

/* .btn { */
/*     display: inline-block; */
/*     font-family: $btn-font-family; */
/*     font-weight: $btn-font-weight; */
/*     color: $body-color; */
/*     text-align: center; */
/*     vertical-align: middle; */
/*     user-select: none; */
/*     background-color: transparent; */
/*     border: $btn-border-width solid transparent; */
/*     @include button-size( */
/*         $btn-padding-y, */
/*         $btn-padding-x, */
/*         $btn-font-size, */
/*         $btn-border-radius */
/*     ); */
/*     line-height: $btn-line-height; */
/*     @include transition($btn-transition); */
/**/
/*     &:hover { */
/*         color: $body-color; */
/*         text-decoration: none; */
/*     } */
/**/
/*     // Disabled comes first so active can properly restyle */
/*     &.disabled, */
/*     &:disabled { */
/*         opacity: $btn-disabled-opacity; */
/*         @include box-shadow(none); */
/*     } */
/**/
/*     &:not(:disabled):not(.disabled):active, */
/*     &:not(:disabled):not(.disabled).active { */
/*         @include box-shadow($btn-active-box-shadow); */
/**/
/*         &:focus { */
/*             @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow); */
/*         } */
/*     } */
/* } */
/**/
/* // Future-proof disabling of clicks on `<a>` elements */
/* a.btn.disabled, */
/* fieldset:disabled a.btn { */
/*     pointer-events: none; */
/* } */

//
// Alternate buttons
//

$btn-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $alert,
    "light": $light8,
    "dark": $dark,
    "required": $required
);

$theme-colors: map-merge($theme-colors, $btn-colors);

@mixin sf-button($color, $value) {
    color: white;
    background: $interaction10;
    border: $interaction10 solid $btn-focus-width;
    border-radius: 0.25rem;
    &:focus,
    &.focus {
        border: solid 1px $interaction11;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
        outline: solid 2px $interaction10;
        outline-offset: 2px;
        text-decoration: underline;
    }
    &:hover {
        &:not([disabled]) {
            color: white;
            background: $interaction11;
            border-color: $interaction11;
        }
    }
    &:active {
        color: white;
        background: $interaction12;
        border-color: $interaction12;
    }
    &:disabled {
        opacity: 0.5;
    }
    &:disabled {
        text-decoration: none;
    }
}

@mixin sf-secondary-button($color, $value) {
    @include button-variant($value, $light, $light6, $light9);
    @include sf-button($color, $value);

    color: $interaction11;
    background: transparent;
    border: solid $btn-focus-width $interaction10;
    &:focus,
    &.focus {
        &:not([disabled]) {
            color: $interaction11;
            background: none;
            border: solid 1px $interaction11;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
            outline: solid 2px $interaction10 !important;
            outline-offset: 2px;
            text-decoration: underline;
        }
    }
    &:hover {
        &:not([disabled]) {
            color: $interaction11;
            background: $interaction1;
            border-color: $interaction11;
        }
    }
    &:active {
        color: $interaction11;
        background: $interaction2 !important;
        border-color: $interaction11 !important;
    }
    &:disabled,
    &.disabled {
        color: $dark11;
        background-color: $light4;
        border-color: $btn-disabled-border-color;
        opacity: 0.5;
        pointer-events: unset;
        cursor: not-allowed;
    }
}

.btn.disabled {
    border-color: $light10;

    &:hover {
        background-color: shade-color($light4, 7.5%);
        border-color: shade-color($light10, 10%);
    }
}

/* @mixin sf-light-button($color, $value) { */
/*     @include button-variant($value, $value); */
/*     @include sf-button($color, $value); */
/*     color: $interaction11; */
/**/
/*     &:focus, */
/*     &.focus, */
/*     &:hover { */
/*         &:not([disabled]) { */
/*             @include focus-outline(darken($interaction, 7.5%)); */
/*         } */
/*     } */
/* } */

@each $color, $value in $btn-colors {
    @if $color == "secondary" {
        .btn-#{$color} {
            @include sf-secondary-button($color, $value);
        }
        /* } @else if $color == "light" { */
        /*     .btn-#{$color} { */
        /*         @include sf-light-button($color, $value); */
        /*     } */
    } @else {
        .btn-#{$color} {
            @include button-variant(
                $interaction10,
                // background
                $interaction10,
                // border-color
                $white,
                // color
                $interaction11,
                // hover-background
                $interaction11,
                // hover-border-color
                $white,
                // hover-color
                $interaction12 // active-background
            );
            @include sf-button($color, $value);
        }
    }
}

@each $color, $value in $theme-colors {
    @if $color == "secondary" {
        .btn-outline-#{$color} {
            @include button-outline-variant($value);
            color: $interaction9;
        }
    } @else {
        .btn-outline-#{$color} {
            @include button-outline-variant($value);
        }
    }
}

//
// Link buttons
//

// Make a button look and behave like a link
/* .btn-link { */
/*     font-weight: $fw-normal; */
/*     color: $link-color; */
/*     text-decoration: $link-decoration; */
/**/
/*     &:hover { */
/*         color: $link-hover-color; */
/*         text-decoration: $link-hover-decoration; */
/*     } */
/**/
/*     &:disabled, */
/*     &.disabled { */
/*         color: $btn-link-disabled-color; */
/*         pointer-events: none; */
/*     } */
/**/
/*     // No need for an active state here */
/* } */

//
// Button Sizes
//

/* .btn-lg { */
/*     @include button-size( */
/*         $btn-padding-y-lg, */
/*         $btn-padding-x-lg, */
/*         $btn-font-size-lg, */
/*         $btn-border-radius-lg */
/*     ); */
/*     line-height: $btn-line-height-lg; */
/* } */
/**/
/* .btn-sm { */
/*     @include button-size( */
/*         $btn-padding-y-sm, */
/*         $btn-padding-x-sm, */
/*         $btn-font-size-sm, */
/*         $btn-border-radius-sm */
/*     ); */
/*     line-height: $btn-line-height-sm; */
/* } */

//
// Block button
//

/* .btn-block { */
/*     display: block; */
/*     width: 100%; */
/**/
/*     // Vertically space out multiple block buttons */
/*     + .btn-block { */
/*         margin-top: $btn-block-spacing-y; */
/*     } */
/* } */

// Specificity overrides
/* input[type="submit"], */
/* input[type="reset"], */
/* input[type="button"] { */
/*     &.btn-block { */
/*         width: 100%; */
/*     } */
/* } */

// Disabled buttons inside ngbDropdowns are already greyed out
.dropdown-item.btn {
    &.disabled,
    &:disabled {
        opacity: 1;
    }
}
.dropdown-text-btn {
    color: $interaction11;
    border: $btn-focus-width solid $interaction10;
    background: transparent;
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $btn-font-size,
        $btn-border-radius
    );
    line-height: $btn-line-height;

    &:focus,
    &.focus {
        &:not([disabled]) {
            color: $interaction11;
            border: $btn-focus-width solid $interaction11;
            outline: none;
            text-decoration: underline;
            background: none;
            box-shadow: none;
        }
    }

    &:hover {
        &:not([disabled]) {
            color: $interaction11;
            border: $btn-focus-width solid $interaction11;
            background: $interaction1;
            text-decoration: none;
        }
    }

    &:disabled,
    &.disabled {
        opacity: 0.3;
        color: $interaction11;
        border: $btn-focus-width solid $interaction10;
        background: none;
        text-decoration: none;
    }
}
