/* @import "angularjs-shims"; */
@import "simplifile";
@import "assets/js/c3/c3";
/* @import "common/ag-grid"; */
@import "../../../node_modules/shepherd.js/dist/css/shepherd.css";

/* .sf-angular, */
/* .sf-angularjs { */
/*     @import "common/highlight"; */
/* } */

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    transition: all 0.2s ease-in-out;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
