@import "colors";

.btn-group.btn-group-toggle {
    margin: 4px;
    gap: 6px;

    label[ngbButtonLabel].btn-secondary {
        display: flex;
        gap: 4px;
        height: 36px;
        border: solid 1px $dark7;
        background-color: transparent;
        color: $interaction11;
        text-decoration: none;
        box-shadow: none;
        white-space: nowrap;
        margin-bottom: 0; // Override default `<label>` value

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }

        &:hover,
        &.hover {
            border-color: $dark10;
            background-color: $interaction0;
            color: $interaction12;
            cursor: pointer;
        }

        &:active,
        &.active {
            border: solid 1px $dark7;
            background-color: $dark7;
            color: $white;

            &:hover,
            &.hover {
                border-color: $dark12;
                background-color: $dark12;
                cursor: pointer;
            }

            &:focus,
            &.focus {
                border: solid 1px $white;
                outline: solid 2px $interaction10;
                outline-offset: 1px;
                background-color: $dark10;
                text-decoration: underline;
            }
        }

        &:nth-of-type(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:nth-last-of-type() {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &.btn-group-toggle {
            margin-bottom: 4px;
        }
    }
}
