@import "../common/variables";

.modal-header {
    background-color: $white;
    align-items: center;

    h4 {
        font-size: 18px;
        font-family: $default-font-family-regular;
        position: relative;
        top: 2px;
    }

    .close {
        padding: 0.5em;
    }
}

.modal-body {
    background-color: $light2;
    padding: 2rem 2rem 1.5rem 2rem;
}

.modal-footer {
    justify-content: flex-start;
    background-color: $white;

    .btn-primary {
        min-width: 5rem;
    }

    .tertiary-action {
        margin-left: auto;
    }
}

.modal-full-width {
    width: 100%;
    max-width: none;
    height: 100%;
    z-index: 999;
    margin: 2rem 0 0;
}
