@import "variables";
@import "colors";

$arrow-size: 4;

.tippy-tooltip .tippy-backdrop {
    background-color: $dark8;
}

/* If `animateFill: false` */
.tippy-tooltip {
    background-color: $dark8;
    //border: 2px solid orange;
    border: 1px solid $dark8;
    //padding: 1px 0 0;
    padding: 2px;
}

/* Default (sharp) arrow */
.tippy-popper[x-placement^="top"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-top-color: $dark8;
    border-top-width: unquote($arrow-size + "px");
    border-right-width: unquote($arrow-size + "px");
    border-left-width: unquote($arrow-size + "px");
    bottom: unquote(-$arrow-size + 1 + "px");
}
.tippy-popper[x-placement^="bottom"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-bottom-color: $dark8;
    border-bottom-width: unquote($arrow-size + "px");
    border-right-width: unquote($arrow-size + "px");
    border-left-width: unquote($arrow-size + "px");
    top: unquote(-$arrow-size + 1 + "px");
}
.tippy-popper[x-placement^="left"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-left-color: $dark8;
    border-right-width: unquote($arrow-size + 1 + "px");
    border-top-width: unquote($arrow-size + 1 + "px");
    border-bottom-width: unquote($arrow-size + 1 + "px");
    right: unquote(-$arrow-size + "px");
}
.tippy-popper[x-placement^="right"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-right-color: $dark8;
    border-right-width: unquote($arrow-size + 1 + "px");
    border-top-width: unquote($arrow-size + 1 + "px");
    border-bottom-width: unquote($arrow-size + 1 + "px");
    left: unquote(-$arrow-size + 1 + "px");
}
/* Round arrow */
.tippy-tooltip.dark-theme .tippy-roundarrow {
    fill: $dark8;
}

.tippy-tooltip .tippy-arrow {
    transform: scale(2);
}

$tour-overlay-opacity: 0.6;

.tour-highlight {
    position: absolute;
    z-index: 9999998;
    box-shadow: 0 0 0 20000px rgba(0, 0, 0, $tour-overlay-opacity);
}

// These are all from sfTour.component.less for the tours
.screen-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.07;
    z-index: 1001;
    top: 0;
    left: 0;
}

.shepherd-step {
    z-index: 10000;
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-left.shepherd-element-attached-middle
    .shepherd-content:before {
    border-right-color: $dark8;
    margin-right: 4px;
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-right.shepherd-element-attached-middle
    .shepherd-content:before {
    border-left-color: $dark8;
    margin-left: 4px;
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title
    .shepherd-content:before,
.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-has-title
    .shepherd-content:before,
.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-has-title
    .shepherd-content:before {
    border-bottom-color: $dark8;
    margin-bottom: 4px;
}

.shepherd-attached {
    border: 8px solid $interaction8;
}

#shepherd-highlighter {
    position: absolute;
    z-index: 9999;
    border: 8px solid $interaction8;
    height: 40px;
    width: 40px;
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center
    .shepherd-content {
    margin-top: 24px;
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-right.shepherd-element-attached-middle
    .shepherd-content {
    position: relative;
    right: 8px;
}

.shepherd-element.shepherd-theme-arrows {
    &.shepherd-has-title .shepherd-content header {
        background: $dark8 !important;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        border-radius: 0px;

        h3 {
            color: white;
            position: relative;

            span {
                position: absolute;
                right: 33px;
            }
        }
    }

    .shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title
        .shepherd-content:before,
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-has-title
        .shepherd-content:before,
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-has-title
        .shepherd-content:before {
        border-bottom-color: $dark8;
    }

    .shepherd-content {
        filter: none;
        -webkit-filter: none;
        -moz-filter: none;

        //.box-glow($dark6, 1 em);
        max-width: 400px;
        border: 4px solid $dark8;

        footer .shepherd-buttons li .shepherd-button {
            &.secondary {
                background: $light8;
                color: $interaction8;
            }

            &.primary {
                background: $interaction8;
            }

            &.shepherd-button {
                text-transform: none;
            }
        }
    }
}
