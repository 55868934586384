/* @import "libs/styles/src/lib/common/variables"; */
/* @import "libs/styles/src/lib/common/functions"; */
/* @import "libs/styles/src/lib/bootstrap/variables"; */
@import "../common/colors";

//
// Base styles
//

/* .alert { */
/*     position: relative; */
/*     padding: $alert-padding-y $alert-padding-x; */
/*     margin-bottom: $alert-margin-bottom; */
/*     border: $alert-border-width solid transparent; */
/*     @include border-radius($alert-border-radius); */
/* } */
/**/
/* // Headings for larger alerts */
/* .alert-heading { */
/*     // Specified to prevent conflicts of changing $headings-color */
/*     color: inherit; */
/* } */
/**/
/* // Provide class for links that match alerts */
/* .alert-link { */
/*     font-weight: $alert-link-font-weight; */
/* } */
/**/
/* // Dismissible alerts */
/* // */
/* // Expand the right padding and account for the close button's positioning. */
/**/
.alert-dismissible {
    /*     padding-right: $close-font-size + $alert-padding-x * 2; */
    /**/
    /*     // Adjust close link position */
    .close,
    .btn-close {
        /*         position: absolute; */
        /*         top: 0; */
        /*         right: 0; */
        /*         z-index: 2; */
        padding: $alert-padding-y $alert-padding-x;
        color: inherit;
    }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

$alert-colors: (
    "primary": (
        background: $d-bg-interaction,
        border: $border-interaction,
        color: $d-text-interaction
    ),
    "success": (
        background: $d-bg-success,
        border: $border-success,
        color: $d-text-success
    ),
    "info": (
        background: $d-bg-info,
        border: $border-info,
        color: $d-text-info
    ),
    "warning": (
        background: $d-bg-required,
        color: $d-text-required,
        border: $border-required
    ),
    "danger": (
        background: $d-bg-alert,
        border: $border-alert,
        color: $d-text-alert
    ),
    "required": (
        background: $d-bg-required,
        border: $border-required,
        color: $d-text-required
    )
);

@each $color, $colors in $alert-colors {
    .alert-#{$color} {
        @include alert-variant(
            map-get($colors, "background"),
            map-get($colors, "border"),
            map-get($colors, "color")
        );
    }
}

@mixin alert-variant($background, $border, $color) {
    --#{$prefix}alert-color: #{$color};
    --#{$prefix}alert-bg: #{$background};
    --#{$prefix}alert-border-color: #{$border};

    @if $enable-gradients {
        background-image: var(--#{$prefix}gradient);
    }

    .alert-link {
        color: shade-color($color, 20%);
    }
}
