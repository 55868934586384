@import "colors";
@import "variables";
@import "mixins";

$box-shadow-size: 5px;

@mixin invalidInput {
    background-color: $required1;
    border-color: $required6;
    color: $required12;

    @include box-glow($required1, $box-shadow-size);

    @include placeholder {
        color: $required12;
    }

    &:focus {
        border-color: $interaction6;
        background-color: $interaction0;

        @include box-glow($interaction1, $box-shadow-size);

        @include placeholder {
            color: $dark6;
        }
    }
}

@mixin invalidAndTouchedInput {
    background-color: $alert1;
    border-color: $alert6;
    color: $alert12;

    @include box-glow($alert1, $box-shadow-size);

    &.dirty-validate {
        // Don't show error colors till control is dirty
        &.ng-pristine {
            border-color: $border-light;
            background-color: $white;

            @include placeholder {
                color: $dark6;
            }
        }

        &.ng-dirty {
            border-color: $border-alert;
            background-color: $l-bg-alert;

            @include placeholder {
                color: $alert12;
            }

            &:focus {
                border-color: $border-alert;
            }
        }
    }

    @include placeholder {
        color: $alert12;
    }

    &:focus {
        /* leave the border red so the user knows there is an error */
        /* border-color: $border-interaction; */
        background-color: $interaction0;
        color: $dark8;

        @include box-glow($interaction1, $box-shadow-size);

        @include placeholder {
            color: $dark6;
        }
    }
}

@mixin clearValidationStyles {
    background-color: transparent;
    background-color: unset;
    border-color: transparent;
    border-color: unset;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

// Required but not touched
input.ng-invalid,
textarea.ng-invalid {
    @include invalidInput;
}

// Required and touched / errored styles
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    @include invalidAndTouchedInput;
}

// Global component validation styling
ngb-timepicker {
    &.ng-invalid .ngb-tp-input {
        @include invalidInput;
    }

    &.ng-invalid.ng-touched .ngb-tp-input {
        @include invalidAndTouchedInput;
    }
}
