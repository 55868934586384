@import "./variables";

.form-control {
    height: $input-height;
}

textarea.form-control {
    height: auto;
}

.form-select {
    height: $input-height;
}
