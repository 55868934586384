@import "../bootstrap/core";

.sf-btn-link {
    padding: 0;
    vertical-align: baseline !important;
}

// Use on buttons within agGrid.
.sf-btn-truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-default {
    background-color: $white;
    color: color-contrast($white);
}

.bg-action {
    background-color: $required10;
    color: color-contrast($required10);
}
.bg-alert {
    background-color: $alert10;
    color: color-contrast($alert10);
}

.bg-error {
    background-color: $alert10;
    color: color-contrast($alert10);
}

.bg-pending {
    background-color: $info10;
    color: color-contrast($info10);
}

.bg-success {
    background-color: $success10;
    color: color-contrast($success10);
}
