@import "./colors";

.toast-close-button {
    color: inherit;
    font-size: 20px;
    opacity: 0.5;

    &:hover {
        opacity: 0.75;
    }
}

.ngx-toastr {
    a {
        color: $interaction;
    }
    a:hover {
        color: $interaction-hover;
    }
}

.growl-success {
    color: $success12;
    background-color: $success1;
    border: 1px solid $success7;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20512%20512'%20width%3D'512'%20height%3D'512'%3E%3Cpath%20fill%3D'rgb(39%2C69%2C10)'%20d%3D'M173.898%20439.404l-166.4-166.4c-9.997-9.997-9.997-26.206%200-36.204l36.203-36.204c9.997-9.998%2026.207-9.998%2036.204%200L192%20312.69%20432.095%2072.596c9.997-9.997%2026.207-9.997%2036.204%200l36.203%2036.204c9.997%209.997%209.997%2026.206%200%2036.204l-294.4%20294.401c-9.998%209.997-26.207%209.997-36.204-.001z'%2F%3E%3C%2Fsvg%3E");
}

.growl-info {
    color: $info12;
    background-color: $info1;
    border: 1px solid $info7;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20512%20512'%20width%3D'512'%20height%3D'512'%3E%3Cpath%20fill%3D'rgb(43%2C107%2C110)'%20d%3D'M256%208C119.043%208%208%20119.083%208%20256c0%20136.997%20111.043%20248%20248%20248s248-111.003%20248-248C504%20119.083%20392.957%208%20256%208zm0%20110c23.196%200%2042%2018.804%2042%2042s-18.804%2042-42%2042-42-18.804-42-42%2018.804-42%2042-42zm56%20254c0%206.627-5.373%2012-12%2012h-88c-6.627%200-12-5.373-12-12v-24c0-6.627%205.373-12%2012-12h12v-64h-12c-6.627%200-12-5.373-12-12v-24c0-6.627%205.373-12%2012-12h64c6.627%200%2012%205.373%2012%2012v100h12c6.627%200%2012%205.373%2012%2012v24z'%2F%3E%3C%2Fsvg%3E");
}

.growl-warning {
    color: $required12;
    background-color: $required1;
    border: 1px solid $required12;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20576%20512'%20width%3D'576'%20height%3D'512'%3E%3Cpath%20fill%3D'rgb(175%2C70%2C0)'%20d%3D'M569.517%20440.013C587.975%20472.007%20564.806%20512%20527.94%20512H48.054c-36.937%200-59.999-40.055-41.577-71.987L246.423%2023.985c18.467-32.009%2064.72-31.951%2083.154%200l239.94%20416.028zM288%20354c-25.405%200-46%2020.595-46%2046s20.595%2046%2046%2046%2046-20.595%2046-46-20.595-46-46-46zm-43.673-165.346l7.418%20136c.347%206.364%205.609%2011.346%2011.982%2011.346h48.546c6.373%200%2011.635-4.982%2011.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884%200-12.356%205.78-11.981%2012.654z'%2F%3E%3C%2Fsvg%3E");
}

.growl-error {
    color: $alert12;
    background-color: $alert1;
    border: 1px solid $alert9;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20512%20512'%20width%3D'512'%20height%3D'512'%3E%3Cpath%20fill%3D'rgb(190%2C7%2C25)'%20d%3D'M256%208C119%208%208%20119%208%20256s111%20248%20248%20248%20248-111%20248-248S393%208%20256%208zm121.6%20313.1c4.7%204.7%204.7%2012.3%200%2017L338%20377.6c-4.7%204.7-12.3%204.7-17%200L256%20312l-65.1%2065.6c-4.7%204.7-12.3%204.7-17%200L134.4%20338c-4.7-4.7-4.7-12.3%200-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3%200-17l39.6-39.6c4.7-4.7%2012.3-4.7%2017%200l65%2065.7%2065.1-65.6c4.7-4.7%2012.3-4.7%2017%200l39.6%2039.6c4.7%204.7%204.7%2012.3%200%2017L312%20256l65.6%2065.1z'%2F%3E%3C%2Fsvg%3E");
}
